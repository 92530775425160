import { createClient } from "./common";

export class CollectionService {
    async getAll(user, mix_id) {
        const client = createClient();
        return client.get('/mix-collections', {
            params: {
                user,
                mix_id
            }
        })
        .then(response => response.data);
    }
    
    async create(name) {
        const client = createClient();
        return client.post('/mix-collections', {
            name
        })
        .then(response => response.data);
    }

    async addItem(mix_collection_id, mix_id) {
        const client = createClient();
        return client.post(`/mix-collections/${mix_collection_id}/items`, {
            mix_id
        })
        .then(response => response.data);
    }

    async getOne(id) {
        const client = createClient();
        return client.get(`/mix-collections/${id}`)
            .then(response => response.data);
    }
}
