import { createClient } from "./common";

export class MixService {
    async createMix(name, components) {
        const client = createClient();
        const result = await client.post('/mixes', {name, components}).then(response => response.data);
        return result;
    }

    async getMix(id) {
        const client = createClient();
        const result = await client.get(`/mixes/${id}`).then(response => response.data);
        return result;
    }

    async getAllMixes(filters) {
        const client = createClient();
        const result = await client.get('/mixes', {params: filters}).then(response => response.data);
        return result;
    }

    async getAllMixesForUser(user, filters) {
        let params = {...filters, user};
        const client = createClient();
        const result = await client.get('/mixes', {params}).then(response => response.data);
        return result;
    }

    async castVote(mix_id, vote) {
        const client = createClient();
        const result = await client.post('/vote', {mix_id, vote}).then(response => response.data.success);
        return result;
    }

    async getComments(mix_id) {
        const client = createClient();
        const result = await client.get(`/mixes/${mix_id}/comments`).then(response => response.data);
        return result;
    }

    async postComment(mix_id, body) {
        const client = createClient();
        const result = await client.post(`/mixes/${mix_id}/comments`, {body}).then(response => response.data);
        return result;
    }
}