import { Layout, Menu, Tag } from "antd";
import { useCallback, useEffect } from "react";
import {Routes, Route, Link, useLocation, useNavigate} from "react-router-dom";
import './App.css';
import CollectionPage from "./collections";
import { Home } from "./home";
import { CreateMix } from "./mixes/create";
import { ViewMix } from "./mixes/view";
import ShishaFlavors from "./shisha-flavors";
import { AuthContext } from "./state/auth";
import authState from "./state/AuthState";
import Login from "./user/login";
import { UserPage } from "./user/page";
import { Profile } from "./user/profile";
import Signup from "./user/signup";
import { useLocalStorage } from "./utils/hooks";

const { Header, Content } = Layout;

function App() {
  const navigate = useNavigate();
  const [authData, setAuthData] = useLocalStorage("mixdb-auth-data-beta", {username: null, token: null, role: null});
  const location = useLocation();

  authState.setToken(authData.token);
  useEffect(() => {
    authState.setToken(authData.token);
  }, [authData]);

  const onSetAuthData = useCallback((authdata) => {
    console.log('set auth data', authdata);
    setAuthData(authdata);
  }, [setAuthData]);

  const onLogoff = useCallback(() => {
    setAuthData({username: null, token: null, role: null});
    navigate("/");
  }, [setAuthData, navigate]);

  return (
    <AuthContext.Provider value={{authData, setAuthData: onSetAuthData}}>
    <Layout>
      <Header style={{position: 'fixed', zIndex: 1, width: '100%', height: '40px', lineHeight: '40px', backgroundColor: '#FAFAFA'}}>
        <Link to="/">
          <div className="logo">
          </div>
        </Link>
        <Menu theme="light" mode="horizontal" selectedKeys={[location.pathname]}>
          <Menu.Item key="/"><Link to="/">Home</Link></Menu.Item>
          <Menu.Item key="/shisha-flavors"><Link to="/shisha-flavors">Flavors</Link></Menu.Item>
          <Menu.Item key="/create"><Link to="/create">New Mix</Link></Menu.Item>
          {authData.username === null ? (
            <>
              <Menu.Item key="/login" style={{marginLeft: "auto"}}><Link to="/login">Login</Link></Menu.Item>
              <Menu.Item key="/signup"><Link to="/signup">Signup</Link></Menu.Item>
            </>
          ) : (
            <>
              {authData.role ? <Menu.Item key="/role" style={{marginLeft: "auto"}}><Tag color="volcano">{authData.role}</Tag></Menu.Item> : null}
              <Menu.Item key="/profile"><Link to="/profile">{authData.username}</Link></Menu.Item>
              <Menu.Item key="/logoff" onClick={onLogoff}>Logoff</Menu.Item>
            </>
          )}
        </Menu>
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 40, backgroundColor: 'white' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="create" element={<CreateMix />} />
          <Route path="mix/:id" element={<ViewMix />} />
          <Route path="/shisha-flavors" element={<ShishaFlavors />} />
          <Route path="user/:username" element={<UserPage />} />
          <Route path="collections/:id" element={<CollectionPage />} />
          <Route path="profile" element={<Profile />} />
        </Routes>
      </Content>
    </Layout>
    </AuthContext.Provider>
  );
}

export default App;
