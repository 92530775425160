import React, { useCallback, useEffect, useState } from "react";
import { Button, Empty } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { MixItem } from "../mixes/item";
import { CollectionService } from "../services/CollectionService";

export default function CollectionPage() {
    const params = useParams();
    const [collection, setCollection] = useState(null);

    useEffect(() => {
        async function load(collectionId) {
            const service = new CollectionService();
            const coll = await service.getOne(collectionId);
            console.log('COLLECTION', coll);
            setCollection(coll);
        }
        load(params.id);
    }, [params.id]);

    const onRemoveMixItem = useCallback(async (item) => {
        console.log(item);
    }, []);

    if (collection === null) {
        return <div><Empty /></div>;
    }

    return (
        <div>
            <h2>{collection.name}</h2>
            {collection.items.map((item, idx) => (
                <div key={item.id} style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{width: '100%'}}>
                        <MixItem {...item.mix}/>
                    </div>
                    <div>
                        <Button 
                            type="text" title="Remove mix from collection" icon={<DeleteOutlined />}
                            onClick={() => onRemoveMixItem(item)}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
}