import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { CollectionService } from "../services/CollectionService";
import { AuthContext } from "../state/auth";
import { Link } from "react-router-dom";
import { MixService } from "../services/MixService";
import { MixList } from "../home";

export function Profile() {
    const {authData} = useContext(AuthContext);
    const [mixes, setMixes] = useState([]);
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        async function load() {
            const mixService = new MixService();
            const userMixes = await mixService.getAllMixesForUser(authData.username);
            const service = new CollectionService();
            const colls = await service.getAll(authData.username);
            setMixes(userMixes);
            setCollections(colls);
        }

        load();
    }, [authData]);

    const handleVote = useCallback(async (mix_id, vote) => {
        const service = new MixService();
        const result = await service.castVote(mix_id, vote);
        if (!result) {
            return;
        }
        let voteValue = 0;
        if (vote === 'up') {
            voteValue = 1;
        }
        else if (vote === 'down') {
            voteValue = -1;
        }
        setMixes(mixList => {
            let mixIdx = mixList.findIndex(x => x.id === mix_id);
            let mix = {...mixList[mixIdx]};
            let change = voteValue - mix.vote;
            mix.vote = voteValue;
            mix.score += change;
            return [...mixList.slice(0, mixIdx), mix, ...mixList.slice(mixIdx+1)];
        });
    }, [setMixes]);



    return (
        <div>
            <h2>Profile</h2>
            <div style={{width: '800px'}}>
                <h3>My Mixes</h3>
                <MixList mixes={mixes} handleVote={handleVote} />
            </div>
            <div>
                <h3>My Collections</h3>
                <div>
                    <Row gutter={[8, 8]}>
                        {collections.map((col, idx) => (
                            <Col key={idx} span={4}>
                                <Card bordered={true}>
                                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                        <Link to={`/collections/${col.id}`}>{col.name}</Link>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

            {/*
            <Alert style={{marginTop: '150px'}} message={(
                <div>
                    Profile page is under development. Here you will be able to
                    <ul>
                        <li>See the mixes you have created.</li>
                        <li>Create and manage mix collections with your mixes or mixes posted by other in a very similar manner as Pinterest.</li>
                        <li>Manage your current collection of flavors which can then be used to show you the available mixes you can make.</li>
                    </ul>
                </div>
            )} type="info" />
            */}
        </div>
    );
}