const dev = {
    apiUrl: 'http://localhost:5001'
};

const prod = {
    apiUrl: 'https://api.mixdb.habeel.net'
};

const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export const apiUrl = config.apiUrl;