import { Button, Form, Input, message} from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { AuthService } from "../services/AuthService";

export default function Signup() {
    const navigate = useNavigate();

    const onSubmit = useCallback(async (values) => {
        if (values.password !== values.password2) {
            message.error('Passwords do not match');
        }
        
        const service = new AuthService();
        const {error, username} = await service.createUser(values.username, values.email, values.password);
        if (error) {
            message.error(error);
            return;
        }
        message.success(`${username} account created`);
        navigate("/login");
    }, [navigate]);

    return (
        <div style={{width: "500px", marginTop: 50, padding: "10px", paddingTop: "50px", backgroundColor: "#F0F0F0"}}>
            <Form name="login" labelCol={{span: 8}} wrapperCol={{span: 16}} onFinish={onSubmit}>
                <Form.Item label="Username" name="username" rules={[{required: true, min: 3, max: 10, message: "Username must be between 3-10 characters"}]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{type: "email", required: true, message: "Please enter a valid email address"}]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Password" name="password" rules={[{required: true, min: 6, max: 30, message: "Password must be minimum 6 characters"}]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item label="Confirm Password" name="password2" rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    })
                ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">Signup</Button>
                </Form.Item>
            </Form>
        </div>
    )
}