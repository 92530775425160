import { Button, Form, Input} from "antd";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import { AuthService } from "../services/AuthService";
import { AuthContext } from "../state/auth";

export default function Login() {
    const navigate = useNavigate();
    const {setAuthData} = useContext(AuthContext);

    const onSubmit = useCallback(async (values) => {
        const service = new AuthService();
        const {access_token, role} = await service.login(values.username, values.password);
        setAuthData({username: values.username, token: access_token, role});
        navigate("/");
    }, [navigate, setAuthData]);

    return (
        <div style={{width: "500px", marginTop: 50, padding: "10px", paddingTop: "50px", backgroundColor: "#F0F0F0"}}>
            <Form name="login" labelCol={{span: 8}} wrapperCol={{span: 16}} onFinish={onSubmit}>
                <Form.Item label="Username" name="username">
                    <Input />
                </Form.Item>
                <Form.Item label="Password" name="password">
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">Login</Button>
                </Form.Item>
            </Form>
        </div>
    )
}