import React from "react";
import { Alert } from "antd";
import { useParams } from "react-router-dom";

export function UserPage() {
    const params = useParams();
    
    return (
        <div>
            <h2>Mixes by {params.username}</h2>
            <Alert message="This feature is under development and will be available soon" type="info" />
        </div>
    );
}
