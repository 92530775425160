import React, { memo, useCallback } from "react";
import {Badge, Button, Divider, Typography} from "antd";
import numeral from "numeral";
import {ArrowDownOutlined, ArrowUpOutlined, CommentOutlined, DiffOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import moment from "moment";

export const MixComponent = memo(function MixComponent({component, totalValue}) {
    return (
        <Typography.Text style={{fontSize: '12px'}}>
            {totalValue !== 0 ? numeral(component.value/totalValue).format("0%") : "-"} {component.flavor.flavor}
        </Typography.Text>
    );
});

export const MixHeader = memo(function MixHeader({id, name, score, vote, commentCount, created, createdBy, onVote, onAddToCollection}) {
    const createdTxt = moment.utc(created).fromNow(true);
    const voteColor = "#98001F";
    const mixName = `${name || "Mix"}#${id}`;
    
    const handleAddToCollection = useCallback(() => {
        const mix = {id, name, mixName, score, vote, created, createdBy};
        onAddToCollection(mix);
    }, [onAddToCollection, id, name, mixName, score, vote, created, createdBy]);

    return (
        <div className="mix-header">
            <Link className="mix-name" to={`/mix/${id}`}>{mixName}</Link>
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <Badge color="#fe3580" count={commentCount} size="small" className="mix-comments">
                    <CommentOutlined />
                </Badge>
                <Button className="mix-add-to-collection" type="text" size="small" onClick={handleAddToCollection}>
                    <DiffOutlined />
                </Button>
                <Typography.Text className="mix-time">{createdTxt}</Typography.Text>
                &nbsp;
                <Typography.Text className="mix-user">
                    <Link to={`/user/${createdBy}`}>{createdBy}</Link>
                </Typography.Text>
                <Divider type="vertical" />
                <Button className="mix-vote-action" type="link" size="small" onClick={() => vote === 1 ? onVote(id, '') : onVote(id, 'up')} icon={<ArrowUpOutlined style={vote === 1 ? {color: voteColor} : {}} />} />
                <Divider type="vertical" />
                <Typography.Text className="mix-votes">{score}</Typography.Text>
                <Divider type="vertical" />
                <Button className="mix-vote-action" type="link" size="small" onClick={() => vote === -1 ? onVote(id, '') : onVote(id, 'down')} icon={<ArrowDownOutlined style={vote === -1 ? {color: voteColor} : {}} />} />
            </div>
        </div>
    );
});

export const MixItem = memo(function MixItem({id, name, components, created, createdBy, score, vote, commentCount, onVote, onAddToCollection}) {
    let totalValue = components.reduce((sum, comp) => sum + (comp.value || 0), 0);

    return (
        <div className="mix-item">
            <MixHeader id={id} name={name} score={score} vote={vote} commentCount={commentCount} created={created} createdBy={createdBy} onVote={onVote} onAddToCollection={onAddToCollection} />
            <div className="mix-body">
                {components.map((component, idx) => (
                    <span key={`mix-${id}-c-${idx}`}>{idx !== 0 ? " x " : ""}<MixComponent key={component.id} component={component} totalValue={totalValue} /></span>
                ))}
            </div>
        </div>
    );
});
