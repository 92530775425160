import axios from "axios";
import { apiUrl } from "../config";
import authState from "../state/AuthState";

export function createClient() {
    let client = axios.create({
        baseURL: apiUrl,
        headers: {
            'Authorization': `Bearer ${authState.getToken()}`,
            'Access-Control-Allow-Origin': '*'
        }
    });

    client.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response && error.response.status === 401) {
            window.location = '/login';
        }
        else {
            return Promise.reject(error);
        }
    });

    return client;
}