import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MixService } from "../services/MixService";
import { CommentList, ComposeComment } from "./comments";
import { Mix } from "./mix";

export function ViewMix() {
    const params = useParams();
    const [mix, setMix] = useState(null);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        async function load() {
            let service = new MixService();
            let result = await service.getMix(params.id);
            let commentLoad = service.getComments(params.id)
            setMix(result);
            let mixComments = await commentLoad;
            setComments(mixComments);
        }
        load();
    }, [params.id]);

    const handleVote = useCallback(async (mix_id, vote) => {
        const service = new MixService();
        const result = await service.castVote(mix_id, vote);
        if (!result) {
            return;
        }
        let voteValue = 0;
        if (vote === 'up') {
            voteValue = 1;
        }
        else if (vote === 'down') {
            voteValue = -1;
        }

        let newMix = {...mix};
        let change = voteValue - newMix.vote;
        newMix.vote = voteValue;
        newMix.score += change;
        setMix(newMix);
    }, [mix, setMix]);

    const handleComment = useCallback(async (body) => {
        const service = new MixService();
        const comment = await service.postComment(params.id, body);
        setComments(list => [comment, ...list]);
    }, [params.id]);

    if (mix === null) {
        return <div>LOADING ...</div>
    }
    return (
        <div>
            <Mix id={mix.id} style={{width: '600px'}} name={mix.name} components={mix.components} vote={mix.vote} score={mix.score} created={mix.created} createdBy={mix.created_by} onVote={handleVote} /> 
            <div style={{width: '600px'}}>
                <ComposeComment style={{marginTop: '15px'}} onComment={handleComment} />
                <CommentList style={{marginTop: '15px'}} comments={comments} />
            </div>
        </div>
    );
}