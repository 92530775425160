import { createClient } from "./common";

export class FlavorService {
    async getFlavors() {
        const client = createClient();
        const flavors = await client.get('/shisha-flavors').then(response => response.data);
        return flavors;
    }

    async updateFlavor(id, props) {
        const client = createClient();
        const success = await client.put(`/shisha-flavors/${id}`, props).then(response => response.data.success);
        return success;
    }

    async getFlavorCategories() {
        const client = createClient();
        const flavors = await client.get('/flavor-categories').then(response => response.data);
        return flavors;
    }
}