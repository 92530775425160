import { createClient } from "./common";

export class AuthService {
    async login(username, password) {
        const client = createClient();
        const result = await client.post('/sessions', {username, password}).then(response => response.data);
        return result;
    }

    async createUser(username, email, password) {
        const client = createClient();
        const result = await client.post('/users', {username, email, password}).then(response => response.data);
        return result;
    }
}