import React, { useCallback, useContext, useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { FlavorService } from "../services/FlavorService";
import { Col, Divider, Row, Select } from "antd";
import { AuthContext } from "../state/auth";

export default function ShishaFlavors() {
    const {authData} = useContext(AuthContext);
    const [filterCompanyId, setFilterCompanyId] = useState(null);
    const [flavorCategories, setFlavorCategories] = useState([]);
    const [shishaFlavors, setShishaFlavors] = useState([]);

    async function loadShishaFlavors() {
        const service = new FlavorService();
        const flavors = await service.getFlavors();
        const categories = await service.getFlavorCategories();
        return {flavors, categories};
    }

    useEffect(() => {
        loadShishaFlavors().then(({flavors, categories}) => {
            setFlavorCategories(categories);
            setShishaFlavors(flavors);
        });
    }, []);

    const handleUpdateCategory = useCallback(async ({data, newValue, ...rest}) => {
        let {id} = data;
        let category = flavorCategories.find(x => x.name === newValue);

        const service = new FlavorService();
        const success = await service.updateFlavor(id, {category_id: category?.id || null});
        if (!success) {
            return;
        }
        setShishaFlavors(list => {
            let newList = [...list];
            let itemIdx = newList.findIndex(f => f.id === id);
            if (itemIdx === -1) {
                return newList;
            }
            newList[itemIdx].category_id = category?.id;
            newList[itemIdx].category = category?.name;
            return newList;
        });
    }, [flavorCategories, setShishaFlavors]);

    const categoryOptions = [{label: '', value: null}, ...flavorCategories.map(fc => ({label: fc.name, value: fc.id}))];
    const companyOptions = shishaFlavors.reduce((cur, val) => {
        if (cur.findIndex(x => x.value === val.company_id) === -1) {
            return [...cur, {label: val.company, value: val.company_id}];
        }
        return cur;
    }, [{label: '', value: null}]);

    const filteredShishaFlavors = shishaFlavors.filter(x => {
        if (filterCompanyId !== null && x.company_id !== filterCompanyId) {
            return false;
        }
        return true;
    });
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', height: window.innerHeight-41, width: window.innerWidth-100}}>
            <Row align="bottom" style={{padding: '10px'}}>
                <Col><b>FILTERS</b></Col>
                <Col><Divider type="vertical" /></Col>
                <Col>
                    <label style={{marginRight: '10px'}}>Company</label>
                    <Select 
                        size="small" style={{width: '150px'}}
                        options={companyOptions} showSearch 
                        filterOption={(input, option) => option.label.toUpperCase().indexOf(input.toUpperCase()) !== -1}
                        onSelect={(value) => setFilterCompanyId(value)}
                    />
                </Col>
            </Row>
            <div style={{flex: 1}}>
                <div className="ag-theme-balham" style={{width: '100%', height: '100%'}}>
                    <AgGridReact
                        rowData={filteredShishaFlavors} 
                    >
                        <AgGridColumn headerName="Company" field="company" sortable filter />
                        <AgGridColumn headerName="Line" field="line" sortable filter />
                        <AgGridColumn headerName="Flavor" field="flavor" sortable filter />
                        <AgGridColumn headerName="Category" field="category" sortable editable={authData.role === 'admin'} cellEditor="agSelectCellEditor" cellEditorParams={{values: categoryOptions.map(o => o.label)}} onCellValueChanged={handleUpdateCategory} />
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
}