import { Button, Comment, Input } from "antd";
import moment from "moment";
import React, { useCallback, useState } from "react";

export function ComposeComment({onComment, style}) {
    const [body, setBody] = useState('');

    const handleComment = useCallback(async (e) => {
        e.preventDefault();
        await onComment(body);
        setBody('');
    }, [body, onComment]);

    return (
        <form onSubmit={handleComment} style={style}>
            <h4>Post Comment</h4>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Input style={{marginRight: '10px'}} value={body} onChange={e => setBody(e.target.value)} />
                <Button type="primary" htmlType="submit">Post</Button>
            </div>
        </form>
    );
}

export function CommentList({comments, style}) {
    return (
        <div style={style}>
            <h4>Comments</h4>
            <div>
                {comments.map(c => <Comment key={c.id} author={c.username} content={c.body} datetime={moment.utc(c.created).fromNow()} />)}
            </div>
        </div>
    );
}
