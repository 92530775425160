import React, { useState } from "react";
import {Button, Divider, InputNumber, Tag, Typography} from "antd";
import numeral from "numeral";
import moment from "moment";
import { Link } from "react-router-dom";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

export function Mix({id, name, components, vote, score, created, createdBy, onVote, style}) {
    let totalValue = components.reduce((sum, comp) => sum + (comp.value || 0), 0);
    const [weight, setWeight] = useState(totalValue);
    const voteColor = "#98001F";
    const createdTxt = moment(created).format("MM/DD/YYYY");

    return (
        <div className="mix-view" style={style}>
            <div style={{padding: '10px', display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                <Typography.Title level={4} style={{flex: 1, marginBottom: 0}}>{name}</Typography.Title>
                <Typography.Text className="mix-time">{createdTxt}</Typography.Text>
                <Typography.Text className="mix-user" style={{marginLeft: '10px'}}><Link to={`/user/${createdBy}`}>{createdBy}</Link></Typography.Text>
                <Divider type="vertical" />
                <Button className="mix-vote-action" type="link" size="small" onClick={() => vote === 1 ? onVote(id, '') : onVote(id, 'up')} icon={<ArrowUpOutlined style={vote === 1 ? {color: voteColor} : {}} />} />
                <Divider type="vertical" />
                <Typography.Text className="mix-votes">{score}</Typography.Text>
                <Divider type="vertical" />
                <Button className="mix-vote-action" type="link" size="small" onClick={() => vote === -1 ? onVote(id, '') : onVote(id, 'down')} icon={<ArrowDownOutlined style={vote === -1 ? {color: voteColor} : {}} />} />
            </div>
            <div style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                <Typography.Title level={5}>Flavors</Typography.Title>
            </div>
            <table className="mix-table">
                <colgroup>
                    <col width="300" />
                </colgroup>
                <thead>
                    <tr>
                        <th>Flavor</th>
                        <th>Percentage</th>
                        <th>Wght: <InputNumber precision={0} value={weight} onChange={(value) => setWeight(value)} /></th>
                    </tr>
                </thead>
                <tbody>
                    {components.map((comp, idx) => (
                        <tr key={idx}>
                            <td>{comp.flavor.flavor} {comp.flavor.category ? <Tag style={{float: 'right'}} color="volcano">{comp.flavor.category}</Tag> : null}</td>
                            <td>{totalValue !== 0 ? numeral(comp.value/totalValue).format("0%") : "-"}</td>
                            <td>{totalValue !== 0 ? numeral(comp.value/totalValue*weight).format("0") : "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}