import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { Button, Checkbox, Col, Empty, Input, List, message, Modal, Radio, Row, Tag, Typography } from "antd";
import { ArrowRightOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { MixItem } from "../mixes/item";
import { CollectionService } from "../services/CollectionService";
import { FlavorService } from "../services/FlavorService";
import { MixService } from "../services/MixService";

import "./index.css";
import { AuthContext } from "../state/auth";

const FilterPanel = memo(function FilterPanel({flavorCategories, onFilter}) {
    const [filtering, setFiltering] = useState(false);
    const [leafType, setLeafType] = useState('');
    const [excludeCategories, setExcludeCategories] = useState([]);

    useEffect(() => {
        const leafTypeValue = leafType === '' ? undefined : leafType;
        const excludeCategoriesValue = excludeCategories.length > 0 ? excludeCategories.map(x => x.toString()).join(",") : null;
        const filters = {leafType: leafTypeValue, excludeCategories: excludeCategoriesValue};
        setFiltering(true);
        onFilter(filters).then(() => setFiltering(false));
    }, [leafType, excludeCategories, setFiltering, onFilter]);

    const handleCategoryChange = useCallback((checkedValues) => {
        setExcludeCategories(checkedValues);
    }, [setExcludeCategories]);

    const categoryOptions = (flavorCategories && flavorCategories.map(x => ({label: x.name, value: x.id}))) || [];

    return (
        <div className="filter-panel" style={{margin: '10px 0'}}>
            <div className="filter-panel-header">
                <Typography.Text strong>Filters</Typography.Text>
            </div>
            <div className="filter-panel-body">
                <Row justify="space-between">
                    <Col>
                        <Typography.Text strong className="filter-label">Leaf Type</Typography.Text>&nbsp;
                        <Radio.Group size="small" optionType="button" value={leafType} onChange={e => setLeafType(e.target.value)} options={[{label: 'All', value: ''}, {label: 'Blonde', value: 'blonde'}, {label: 'Dark', value: 'dark'}]} />
                    </Col>
                    <Col>
                        {filtering ? <Tag color="processing">FILTERING ...</Tag> : null}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Typography.Text strong className="filter-label">Exclude Categories</Typography.Text>&nbsp;
                        <Checkbox.Group className="checkbox-group-sm checkbox-exclude" options={categoryOptions} value={excludeCategories} onChange={handleCategoryChange} />
                    </Col>
                </Row>
            </div>
        </div>
    );
});

const MixCollectionUtils = memo(function MixCollectionUtils({ children }) {
    const {authData} = useContext(AuthContext);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [collections, setCollections] = useState([]);
    const [mix, setMix] = useState(null);
    const [newCollectionName, setNewCollectionName] = useState("");

    const onAddToCollection = useCallback(async (mix1) => {
        setMix(mix1);
        const service = new CollectionService();
        const colls = await service.getAll(authData.username, mix1.id);
        setCollections(colls);
        setShowAddDialog(true);
    }, [setMix, setCollections, setShowAddDialog, authData]);

    const handleAddToNewCollection = useCallback(async () => {
        if (newCollectionName === "") {
            message.error("Please specify a collection name");
            return;
        }
        const service = new CollectionService();
        const collection = await service.create(newCollectionName);
        const item = await service.addItem(collection.id, mix.id);
        console.log(item);
        setCollections(colls => [...colls, collection]);
        setShowAddDialog(false);
    }, [mix, newCollectionName, setCollections, setShowAddDialog]);

    const handleAddToCollection = useCallback(async (collectionId) => {
        const service = new CollectionService();
        const item = await service.addItem(collectionId, mix.id);
        console.log(item);
        setShowAddDialog(false);
    }, [mix, setShowAddDialog]);

    const mixName = mix?.mixName;

    return (
        <>
            <Modal title={`Add Mix To Collection`} visible={showAddDialog} footer={null} onCancel={() => setShowAddDialog(false)}>
                <Row gutter={24} align="middle">
                    <Col flex="none">
                        <Tag color="volcano">{mixName}</Tag> <ArrowRightOutlined />
                    </Col>
                    <Col flex="auto" style={{borderLeft: "2px dashed #eee"}}>
                        <Row>
                            <Col flex="auto">
                                <Input type="text" value={newCollectionName} placeholder="Add to new collection" onChange={e => setNewCollectionName(e.target.value)} />
                            </Col>
                            <Col flex="none">
                                <Button type="default" onClick={() => handleAddToNewCollection()}>Add</Button>
                            </Col>
                        </Row>
                        <List 
                            size="small"
                            itemLayout="horizontal"
                            dataSource={collections}
                            renderItem={item => (
                                <List.Item 
                                    actions={[
                                        item.includes_mix ? (
                                            <div title={`Already added to ${item.name}`} style={{width: '24px', height: '24px', textAlign: 'center', fontSize: '14px'}}>
                                                <CheckOutlined style={{color: "green", fontWeight: "bold"}} />
                                            </div>
                                        ) : (
                                            <Button size="small" type="text" icon={<PlusOutlined />} onClick={() => handleAddToCollection(item.id)} />
                                        )
                                    ]}
                                >
                                    {item.name}
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                
            </Modal>
            {children({onAddToCollection})}  
        </>
    );
});

export const MixList = memo(function MixList({mixes, handleVote}) {

    return (
        <div>
            {mixes.length > 0 ? (
                <MixCollectionUtils>
                    {({onAddToCollection}) => (
                        mixes.map((mix) => <MixItem key={mix.id} id={mix.id} name={mix.name} components={mix.components} created={mix.created} createdBy={mix.created_by} score={mix.score} vote={mix.vote} commentCount={mix.comment_count} onVote={handleVote} onAddToCollection={onAddToCollection} />)
                    )}
                </MixCollectionUtils>
            ) : (
                <Empty description={'No mixes found with that filter'} />
            )}
        </div>
    );
});

export function Home() {
    const [flavorCategories, setFlavorCategories] = useState([]);
    const [mixes, setMixes] = useState([]);

    async function load(filters) {
        const service = new MixService();
        const result = await service.getAllMixes(filters);
        setMixes(result);
    }

    async function loadFlavorCategories() {
        const service = new FlavorService();
        const result = await service.getFlavorCategories();
        setFlavorCategories(result);
    }

    useEffect(() => {
        loadFlavorCategories().then(() => {
            load({});
        });
    }, []);

    const handleFilter = useCallback(async (filters) => {
        await load(filters);
    }, []);

    const handleVote = useCallback(async (mix_id, vote) => {
        const service = new MixService();
        const result = await service.castVote(mix_id, vote);
        if (!result) {
            return;
        }
        let voteValue = 0;
        if (vote === 'up') {
            voteValue = 1;
        }
        else if (vote === 'down') {
            voteValue = -1;
        }
        setMixes(mixList => {
            let mixIdx = mixList.findIndex(x => x.id === mix_id);
            let mix = {...mixList[mixIdx]};
            let change = voteValue - mix.vote;
            mix.vote = voteValue;
            mix.score += change;
            return [...mixList.slice(0, mixIdx), mix, ...mixList.slice(mixIdx+1)];
        });
    }, [setMixes]);

    return (
        <div style={{width: '800px'}}>
            <FilterPanel flavorCategories={flavorCategories} onFilter={handleFilter} />
            <MixList mixes={mixes} handleVote={handleVote} />
        </div>
    );
}