
class AuthState {
    constructor() {
        this.token = null;
    }

    setToken(token) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }
}

const authState = new AuthState();

export default authState;
