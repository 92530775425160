
import React, { useCallback, useContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { Alert, Button, Input, InputNumber, PageHeader, Select, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {groupBy, keyBy} from "lodash";
import numeral from "numeral";
import { FlavorService } from "../services/FlavorService";
import { MixService } from "../services/MixService";
import { AuthContext } from "../state/auth";


export function CreateMix() {
    const {authData} = useContext(AuthContext);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [selectedFlavor, setSelectedFlavor] = useState(null);
    const [components, setComponents] = useState([]);
    const [flavors, setFlavors] = useState([]);

    useEffect(() => {
        async function loadFlavors() {
            const service = new FlavorService();
            const result = await service.getFlavors();
            setFlavors(result);
        }

        loadFlavors();
    }, []);

    const onAddFlavor = useCallback((shishaFlavorId) => {
        setComponents(comps => ([...comps, {shishaFlavorId, value: null}]));
    }, []);

    const onRemoveFlavor = useCallback((idx) => {
        setComponents(comps => ([...comps.slice(0, idx), ...comps.slice(idx+1)]));
    }, [setComponents]);

    const onSelectFlavor = useCallback(shishaFlavorId => {
        setSelectedFlavor(shishaFlavorId);
    }, [setSelectedFlavor]);

    const onSetShishaFlavorValue = useCallback((compIdx, value) => {
         setComponents(comps => {
            let copy = comps.slice();
            copy[compIdx].value = value;
            return copy;
        });       
    }, []);

    const createMix = useCallback(async () => {
        const service = new MixService();
        const {id} = await service.createMix(name, components);
        navigate(`/mix/${id}`);
    }, [components, name, navigate]);

    const flavorById = keyBy(flavors, 'id');
    const flavorsToString = flavors.reduce((cur, flavor) => {
        cur[flavor.id] = `${flavor.flavor}${flavor.line ? " " + flavor.line : ""} ${flavor.company}`.toLowerCase();
        return cur;
    }, {});

    let totalValue = components.reduce((sum, comp) => sum + (comp.value || 0), 0);
    let groupedFlavors = groupBy(flavors, f => f.company + (f.line ? ` ${f.line}`: ''));

    if (authData.username === null) {
        return (
            <div>
                <Alert message="Please login to post a new mix" type="error" />
            </div>
        );
    }

    return (
        <div>
            <PageHeader title="Shisha Mix" subTitle="Create a new mix" />
            <div style={{width: '600px', backgroundColor: '#FAFAFA'}}>
                <div style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                    <Typography.Title level={5}>Name</Typography.Title>
                    <Input value={name} onChange={e => setName(e.target.value)} placeholder="Please enter a name for the mix" />
                </div>
                <div style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                    <Typography.Title level={5}>Flavors</Typography.Title>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Select
                            style={{width: '100%'}} 
                            showSearch
                            value={selectedFlavor} 
                            onChange={(value) => onSelectFlavor(value)}
                            filterOption={(input, optionData) => {
                                if (optionData.options) {
                                    return false;
                                }
                                let option = optionData;
                                let flavorText = flavorsToString[option.value];
                                return flavorText.indexOf(input.toLowerCase()) >= 0;
                            }}
                        >
                            {Object.keys(groupedFlavors).map((company) => (
                                <Select.OptGroup key={company} label={company}>
                                    {groupedFlavors[company].map((flavor) => (
                                        <Select.Option key={flavor.id} value={flavor.id}>
                                            {flavor.flavor}
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                            ))}
                        </Select>
                        <Button onClick={() => onAddFlavor(selectedFlavor)}>Add Flavor</Button>
                    </div>
                </div>
                <table className="mix-table">
                    <colgroup>
                        <col width="300" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Flavor</th>
                            <th>Weight</th>
                            <th>Percentage</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {components.length === 0 ? (
                            <tr>
                                <td colSpan={3}>Please add flavors to create a mix</td>
                            </tr>
                        ): null}
                        {components.map((comp, idx) => (
                            <tr key={idx}>
                                <td>{flavorById[comp.shishaFlavorId].flavor}</td>
                                <td><InputNumber precision={0} size="small" value={comp.value} onChange={(value) => onSetShishaFlavorValue(idx, value)} /></td>
                                <td>{totalValue !== 0 ? numeral(comp.value/totalValue).format("0%") : "-"}</td>
                                <td><Button type="text" shape="circle" icon={<DeleteOutlined />} onClick={() => onRemoveFlavor(idx)} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-around'}}>
                    <Button type="primary" size="large" onClick={createMix}>Create Mix</Button>
                </div>
            </div>
        </div>
    );
}
